import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "./redux/hooks";

interface PropsI {
  children: any;
};

/********************************
 * RequestAuth
 * exported to App.tsx
 *******************************/
export default function RequestAuth({ children }: PropsI) {
  const userState = useAppSelector((state) => state.user.userState);
  let location = useLocation();

  if (!userState) {
    // provides a redirection to the login page, providing sucurity and
    // good UX
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }

  return children;
}