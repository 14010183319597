interface PropsI {
  projects: string;
  main: string;
  blog: string;
  messages: string;
  login: string;
  skills: string;
  updateProjects: string;
  invoice: string;
}

export const paths: Readonly<PropsI> = {
  main: "main",
  projects: "main/projects",
  blog: "main/blog",
  messages: "main/messages",
  login: "/",
  skills: "main/skills",
  updateProjects: "main/updateprojects",
  invoice: "main/invoice",
};
