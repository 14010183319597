import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
  },
});


// Inferring these types from the store itself means 
// that they correctly update as you add more state
// slices or modify middleware settings.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;


// ref: https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types