/******************************************
 * This allows you to import them into any component 
 * file that needs to use the hooks, and avoids 
 * potential circular import dependency issues.
 * ref: https://react-redux.js.org/using-react-redux/usage-with-typescript
 ******************************************/

import type { RootState, AppDispatch } from "./store";
import { useDispatch, useSelector } from "react-redux";

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();